import React, { useState } from "react";
import { Title, Body, Footer } from "./index";

import {
  ContactInformationModal,
  DomainTypeModal,
  FunctioningLinksModal,
  WebsiteDateModal
} from "~/components/modals";

const FindARetailerContent = (): JSX.Element => {
  return (
      <Body title="Find a Retailer">
        <div className="flex flex-wrap justify-center items-center w-full text-black">
          <h3 className="px-3 text-3xl text-center w-full">ERROR 404</h3>
          <p className="px-3 mt-5 text-xs text-center w-full">Not Found</p>
          <p className="px-3 mt-5 text-xs text-center w-full">The requested URL/fgereg was not found on this server.</p>
          <p className="px-3 mt-5 text-xs text-center w-full">Additionally, a 404 Not Found error was encountered while trying to use an ErrorDocument to handle the request.</p>
        </div>
      </Body>
  );
};

const ActiveLifeFindARetailerPage = (): JSX.Element => {
  const [domainTypeModalVisible, setDomainTypeModalVisible] = useState<boolean>(false);
  const [domainTypeModalRating, setDomainTypeModalRating] = useState<number>(-1);
  const [websiteDateModalVisible, setWebsiteDateModalVisible] = useState<boolean>(false);
  const [websiteDateModalRating, setWebsiteDateModalRating] = useState<number>(-1);
  const [contactInformationModalVisible, setContactInformationModalVisible] = useState<boolean>(false);
  const [contactInformationModalRating, setContactInformationModalRating] = useState<number>(-1);
  const [functioningLinksModalVisible, setFunctioningLinksModalVisible] = useState<boolean>(false);
  const [functioningLinksModalRating, setFunctioningLinksModalRating] = useState<number>(-1);

  return (
    <>
      <Title modalFunctions={[setContactInformationModalVisible, setFunctioningLinksModalVisible]} modalRatings={[contactInformationModalRating, functioningLinksModalRating]} />
      <FindARetailerContent />
      <Footer modalFunctions={[setDomainTypeModalVisible, setWebsiteDateModalVisible]} modalRatings={[domainTypeModalRating, websiteDateModalRating]} />
      {domainTypeModalVisible && (
        <DomainTypeModal
          modalRating={domainTypeModalRating}
          modalVisible={domainTypeModalVisible}
          setModalRating={setDomainTypeModalRating}
          setModalVisible={setDomainTypeModalVisible}
        />
      )}
      {websiteDateModalVisible && (
        <WebsiteDateModal
          modalRating={websiteDateModalRating}
          modalVisible={websiteDateModalVisible}
          setModalRating={setWebsiteDateModalRating}
          setModalVisible={setWebsiteDateModalVisible}
        />
      )}
      {contactInformationModalVisible && (
        <ContactInformationModal
          modalRating={contactInformationModalRating}
          modalVisible={contactInformationModalVisible}
          setModalRating={setContactInformationModalRating}
          setModalVisible={setContactInformationModalVisible}
        />
      )}
      {functioningLinksModalVisible && (
        <FunctioningLinksModal
          modalRating={functioningLinksModalRating}
          modalVisible={functioningLinksModalVisible}
          setModalRating={setFunctioningLinksModalRating}
          setModalVisible={setFunctioningLinksModalVisible}
        />
      )}
    </>
  )
};

export default ActiveLifeFindARetailerPage;

